<template>
    <div>
        <div class="text-center">
            <h2>Ιωάννης Καραβίδας</h2>
        </div>
        <v-container class="main-text text-container">
            <div>
                <v-img :width="415" :height="415" style="float: left;" class="bradius" :src="require('@/assets/partners/Photo-I-Karavidas.jpg')" />
                <p class="cv-text">
                    Ο Δρ. Ιωάννης Καραβίδας αποφοίτησε το 2017 από το Τμήμα Γεωπονικής Βιοτεχνολογίας του Γεωπονικού Πανεπιστημίου Αθηνών (ΓΠΑ). Το 2023 ολοκλήρωσε την Διδακτορική του διατριβή στο Εργαστήριο Κηπευτικών Καλλιεργειών του Τμήματος Φυτικής Παραγωγής ΓΠΑ, όπου έως σήμερα εκπονεί την Μεταδιδακτορική του έρευνα. Τα ερευνητικά του ενδιαφέροντα περιλαμβάνουν την αποδοτικότητα χρήσης νερού και θρεπτικών των φυτών, τα βιώσιμα συστήματα καλλιέργειας καθώς και τις αποκρίσεις των κηπευτικών σε παράγοντες αβιοτικής καταπόνησης. Το συγγραφικό του έργο περιλαμβάνει 11 εργασίες δημοσιευμένες σε έγκριτα διεθνή επιστημονικά περιοδικά με κριτές που διαθέτουν Impact Factor στο Journal Citation Reports, 12 επιστημονικές εργασίες σε πλήρη πρακτικά διεθνών συνεδρίων και πάνω από 35 εργασίες σε πρακτικά συνεδρίων. Έως σήμερα έχει συμμετάσχει ως μέλος ερευνητικής ομάδας σε 3 ευρωπαϊκά (TRUE, RADIANT, ECONUTRI), 3 εθνικά (NUTRISENSE, ΒΙΟΠΡΟΣΑΡΜΟΓΗ, OPTIMUS) και 2 ιδιωτικά ερευνητικά προγράμματα (IGC-Green, BIOSPRIM).
                </p>
            </div>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.cv-card {
    border: 1px solid #D9D9D9;
    border-radius: 25px;
    text-align: center;
}
.bradius {
    border-radius: 25px;
    margin-right: 15px;
}
.cv-text {
    display: inline;
}
.main-text {
    width: 60vw;
    margin: auto;
    color: #646464;
}
</style>
